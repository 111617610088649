import { NotificationManager } from "react-notifications";
import {
  API_METHOD,
  DEFAULT_AUTH_BASE_URL,
  GET_ROLE_PERMISSIONS,
  REQUEST_TYPE,
} from "../../config/APIConfigList";
import { RequestHandler } from "../../util/APIRequestHandler";
import { HiUsers } from "react-icons/hi";
import {
  RiBankLine,
  RiFileSettingsLine,
  RiShieldCheckFill,
} from "react-icons/ri";
import { GiOrganigram, GiTicket } from "react-icons/gi";
import {
  MdPayment,
  MdMiscellaneousServices,
  MdManageAccounts,
  MdSettings,
  MdSecurity,
  MdSettingsApplications,
  MdAltRoute,
  MdDeviceHub,
} from "react-icons/md";
import { BsBank2, BsPersonLinesFill } from "react-icons/bs";
import {
  FaUpload,
  FaFileImport,
  FaUserAlt,
  FaHistory,
  FaUsers,
  FaTachometerAlt,
  FaUsersCog,
  FaFileInvoice,
} from "react-icons/fa";
import { GoGitMerge } from "react-icons/go";
import { GiProcessor } from "react-icons/gi";
import { TbFileSettings } from "react-icons/tb";
import { BiTransfer } from "react-icons/bi";
import { userSignOut } from "../../appRedux/actions/Auth";
import { SESSION_STORAGE_ITEM } from "../../config/StaticProperties";
import { DEFAULT_PROFILE_IMG } from "../../routes/main/Profile/ProfileConst";
import { SnippetsOutlined } from "@ant-design/icons";
// Start of Menu Permission IDs (Only store in UI)
export const M_EBS_PAY_MAIN_MENU_DASHBOARD = "DASHBOARD";
export const M_EBS_PAY_MAIN_MENU_MAINTANANCE = "MAINTANANCE";
export const M_EBS_PAY_MAIN_MENU_COMPLIANCE = "COMPLIANCE";
export const M_EBS_PAY_MAIN_MENU_PAYMENT = "PAYMENT";
export const M_EBS_PAY_MAIN_MENU_CONFIGURATION = "CONFIGURATION";
export const M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER = "CONVERTER_FORMATTER";
export const M_EBS_PAY_MAIN_MENU_SECURITY_MANAGEMENT = "SECURITY_MANAGEMENT";
export const M_EBS_PAY_MAIN_MENU_REPORT = "REPORT";
// TEMP FOR RTP PAYMENT
export const M_EBS_PAY_MAIN_MENU_RTPPAYMENT = "RT_PAYMENT";
// End of Menu Permission IDs

// Start of Sub-Menu Permission IDs (Same store in Backend/DB)
export const M_EBS_PAY_DASHBOARD = "M-EBS-PAY-DASHBOARD";
export const M_EBS_PAY_FI = "M-EBS-PAY-FI";
export const M_EBS_PAY_FI_DETAIL = "M-EBS-PAY-FI-DETAIL";
export const M_EBS_PAY_SECURITY_USER = "M-EBS-PAY-SECURITY-USER";
export const M_EBS_PAY_CUSTOMER = "M-EBS-PAY-FI-CUSTOMER";
export const M_EBS_PAY_CUSTOMER_DETAILS = "M-EBS-PAY-FI-CUSTOMER"; // NEW CUSTOMER DETAILS SCREEN
export const M_EBS_PAY_ORGANIZATION = "M-EBS-PAY-FI-ORGANIZATION";
export const M_EBS_PAY_FILE_UPLOAD = "M-EBS-PAY-FI-FILE-UPLOAD";
export const M_EBS_PAY_PARTY_SERVICE = "M-EBS-PAY-PARTY-SERVICE";
export const M_EBS_PAY_PARING_SERVICE = "M-EBS-PAY-PARING-SERVICE";
export const M_EBS_PAY_PAYMENT_BANKSETUP = "M-EBS-PAY-PAYMENT-BANKSETUP";
export const M_EBS_PAY_BENEFICIARY = "M-EBS-PAY-BENEFICIARY";
export const M_EBS_PAY_KYC = "M-EBS-PAY-KYC";
export const M_EBS_PAY_KYB = "M-EBS-PAY-KYB";
export const M_EBS_PAY_PAYMENT_INITIAL = "M-EBS-PAY-PAYMENT-INITIAL";
export const M_EBS_PAY_CROSS_BORDER_PAYMENT_INITIATE = "M-EBS-PAY-CROSS-BORDER-PAYMENT-INITIATE";
export const M_EBS_PAY_CROSS_BORDER_PAYMENT_APPROVAL_AND_STATUS = "M-EBS-PAY-CROSS-BORDER-PAYMENT-APPROVAL-AND-STATUS";
export const M_EBS_PAY_PAYMENT_STATUS = "M-EBS-PAY-PAYMENT-STATUS";
export const M_EBS_PAY_LIMITAPPROVAL = "M-EBS-PAY-PAYMENT-LIMITAPPROVAL";
export const M_EBS_PAY_APPROVAL = "M-EBS-PAY-PAYMENT-APPROVAL";
export const M_EBS_PAY_DISBAPPROVAL = "M-EBS-PAY-PAYMENT-DISBAPPROVAL";
export const M_EBS_PAY_COMPLIANCE_APPROVAL =
  "M-EBS-PAY-PAYMENT-COMPLIANCEAPPROVAL";
export const M_EBS_PAY_TRANSACTION_COMPLIANCE =
  "M-EBS-PAY-PAYMENT-TRANS-COMPLIANCE";
export const M_EBS_PAY_SECURITY_ROLE = "M-EBS-PAY-SECURITY-ROLE";
export const M_EBS_PAY_FI_CONFIGURATION = "M-EBS-PAY-FI-CONFIGURATION";
export const M_EBS_PAY_CONV_PROCESSING = "M-EBS-PAY-CONV-PROCESSING";
export const M_EBS_PAY_CONV_CONNECTIVITY_SETUP =
  "M-EBS-PAY-CONV-CONNECTIVITY-SETUP";
export const M_EBS_PAY_CONV_MESSAGE = "M-EBS-PAY-CONV-MESSAGE";
export const M_EBS_PAY_CONV_ISOCONFIG = "M-EBS-PAY-CONV-ISOCONFIG";
export const M_EBS_PAY_CONV_SENDER_RECEIVER_SETUP =
  "M-EBS-PAY-CONV-SENDER-RECEIVER-SETUP";
export const M_EBS_PAY_CONV_ROUTE_CODE_SETUP =
  "M-EBS-PAY-CONV-ROUTE-CODE-SETUP";
export const M_EBS_PAY_CONV_SERVICE_ROUTING_CONF =
  "M-EBS-PAY-CONV-SERVICE-ROUTING-CONF";
export const M_EBS_PAY_CONV_FILEUPLOAD = "M-EBS-PAY-CONV-FILEUPLOAD";
export const M_EBS_PAY_REPORT = "M-EBS-PAY-REPORT";

export const M_EBS_PAY_RTP_INITIATION = "M-EBS-PAY-RTP-INITIATION";
export const M_EBS_PAY_RTP_STATUS = "M-EBS-PAY-RTP-STATUS";
// End of Sub-Menu Permission IDs

export const SCREEN_CODES = {
  maintenance: [
    M_EBS_PAY_DASHBOARD,
    M_EBS_PAY_FI,
    M_EBS_PAY_CUSTOMER,
    M_EBS_PAY_ORGANIZATION,
    M_EBS_PAY_FILE_UPLOAD,
    M_EBS_PAY_PARTY_SERVICE,
    M_EBS_PAY_PARING_SERVICE,
    M_EBS_PAY_BENEFICIARY,
  ],
  compliance: [M_EBS_PAY_KYC, M_EBS_PAY_KYB],
  payment: [
    M_EBS_PAY_PAYMENT_INITIAL,
    M_EBS_PAY_PAYMENT_STATUS,
    M_EBS_PAY_APPROVAL,
    M_EBS_PAY_COMPLIANCE_APPROVAL,
  ],
};

export const CHECK_PAGE_PERMISSION = (permissionList, pageCode) => {
  let isGiven = false;
  permissionList.map((data) => {
    if (
      data?.screen?.pageCode.replace(/\s/g, "") === pageCode?.replace(/\s/g, "")
    ) {
      isGiven = true;
    }
    return null;
  });
  return isGiven;
};
export const ROLE_PERMISSIONS = (dispatch) => {
  return RequestHandler({
    ...GET_ROLE_PERMISSIONS,
  })
    .then(async (result) => {
      if (result?.success) {
        // set role Permissions
        if (
          result?.data &&
          result?.data?.permission &&
          result?.data?.permission?.length > 0
        ) {
          sessionStorage.setItem(
            SESSION_STORAGE_ITEM.USER_NAME,
            result?.data?.name
          );
          sessionStorage.setItem(
            SESSION_STORAGE_ITEM.USER_EMAIL,
            result?.data?.username
          );
          sessionStorage.setItem(
            SESSION_STORAGE_ITEM.USER_ROLE,
            // result?.data?.user?.roleName,
            result?.data?.permission?.[0]?.roleDto?.roleName
          );
          sessionStorage.setItem(
            SESSION_STORAGE_ITEM.PARTY_ID,
            result?.data?.user?.partyId
          );
          dispatch({
            type: "CHANGE_ROLE_PERMISSION",
            payload:
              result?.data && result?.data?.permission
                ? result?.data?.permission
                : [],
          });
          dispatch({
            type: "USER_DETAILS",
            payload: result?.data && result?.data ? result?.data : null,
          });
          return result.data;
        } else {
          // display error message
          NotificationManager.error(
            "No permissions found for the user, please contact the administrator"
          );
          dispatch(userSignOut());
          return null;
        }
      } else {
        // display error message
        NotificationManager.error(
          "No permissions found for the user, please contact the administrator"
        );
        dispatch(userSignOut());
        return null;
      }
    })
    .catch((error) => {
      NotificationManager.error(
        "Error while fetching role permissions , please contact the administrator"
      );
      dispatch(userSignOut());
      return null;
    });
};
export const CHECK_PERMISSION_BY_URL = async (rolePermission, url) => {
  let rolePermissionList = [];
  rolePermission?.map((data) => {
    if (data?.screen?.pageCode) {
      rolePermissionList?.push(data?.screen?.pageCode?.replace(/\s/g, ""));
    }
    return null;
  });
  let permission = false;
  let urlObj = MENULIST.filter((filterData) => {
    // SETUP FOR DYNAMIC PARAMETER MANAGEMENT
    if (filterData?.link?.split(":")?.length > 1) {
      let tempArr = url?.split("/");
      tempArr?.pop();
      let newUrl = tempArr?.join("/") + `/`;
      return filterData?.link?.split(":")?.[0] === newUrl;
    } else {
      return filterData?.link === url;
    }
  });
  urlObj = urlObj.length > 0 ? urlObj[0] : {};
  if (rolePermissionList?.includes(urlObj?.menuId)) {
    permission = true;
  }
  return permission;
};
export const GENERATE_MENU_LIST = (rolePermission) => {
  let rolePermissionList = [];
  rolePermission?.map((data) => {
    if (data?.screen?.pageCode) {
      rolePermissionList?.push(data?.screen?.pageCode?.replace(/\s/g, ""));
    }
    return null;
  });
  if (rolePermissionList && rolePermissionList?.length > 0) {
    //get all the main menu
    let mainMenuList = MENULIST.filter((filterData) => {
      return filterData?._isMainMenu;
    });
    mainMenuList?.map((data) => {
      // get sub menus by main menu id
      data["subMenu"] = MENULIST.filter((filterData) => {
        return (
          !filterData?._isMainMenu &&
          filterData?.mainMenuId === data?.menuId &&
          rolePermissionList?.includes(filterData?.menuId?.replace(/\s/g, ""))
        );
      });
      //help to display main menu, if sub menu is available then only it will show the main menu
      if (data?.subMenu?.length === 0) {
        data["enableMainMenu"] = false;
      } else {
        data["enableMainMenu"] = true;
      }
      return data;
    });
    return mainMenuList;
  } else {
    return [];
  }
};
export const MENU_ICON_SPAN_STYLE = { marginTop: "13px" };
export const MENU_SUB_ICON_SPAN_STYLE = { marginTop: "3px" };
export const MENU_ICON_SIZE = 15;
export const MENULIST = [
  {
    _isMainMenu: true,
    menuId: M_EBS_PAY_MAIN_MENU_DASHBOARD,
    mainMenuId: "",
    link: "",
    icon: (
      <i className="icon icon-dasbhoard" style={{ fontSize: MENU_ICON_SIZE }} />
    ),
    title: "sidebar.dashboard",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_DASHBOARD,
    mainMenuId: M_EBS_PAY_MAIN_MENU_DASHBOARD,
    link: "/main/dashboard/dashboard",
    icon: (
      <i className="icon icon-crypto" style={{ fontSize: MENU_ICON_SIZE }} />
    ),
    title: "sidebar.dashboard.crypto",
  },
  {
    _isMainMenu: true,
    menuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    mainMenuId: "",
    link: "",
    icon: (
      <span className="icon" style={MENU_ICON_SPAN_STYLE}>
        <MdSettings size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.maintanance",
  },
  // FI COMMENTED FOR FI PROFILEING SCREEN
  // {
  //   _isMainMenu: false,
  //   menuId: M_EBS_PAY_FI,
  //   mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
  //   link: "/main/maintenance/financial/institution",
  //   icon: (
  //     <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
  //       <BsBank2 size={MENU_ICON_SIZE} />
  //     </span>
  //   ),
  //   title: "sidebar.financeial_institution",
  // },
  // FI PROFILING SCREEN
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_FI,
    mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/fiprofile/list",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <BsBank2 size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.financeial_institution",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_FI_DETAIL,
    // mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/fiprofile",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <HiUsers size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.financeial_institution",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_FI,
    // mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/fiprofile/details/:profId",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <HiUsers size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.financeial_institution",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_ORGANIZATION,
    mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/financial/organization",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <GiOrganigram size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.organization",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CUSTOMER,
    mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/customer",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <HiUsers size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.customer",
  },
  // CUSTOMER DETAILS SCREEN
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CUSTOMER_DETAILS,
    // mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/customerdetails/:custId",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <HiUsers size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.customer",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_PARTY_SERVICE,
    mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/serviceprovider",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdMiscellaneousServices size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.serviceprovider",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_PARTY_SERVICE,
    mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/partyservices",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdMiscellaneousServices size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.partyservices",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_PARING_SERVICE,
    mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/paringmanagement",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdManageAccounts size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.paring_management",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_BENEFICIARY,
    mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/beneficiary",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <FaUsers size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.beneficiary",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_PAYMENT_BANKSETUP,
    mainMenuId: M_EBS_PAY_MAIN_MENU_MAINTANANCE,
    link: "/main/maintenance/banksetup",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdManageAccounts size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.bankSetup",
  },
  {
    _isMainMenu: true,
    menuId: M_EBS_PAY_MAIN_MENU_COMPLIANCE,
    mainMenuId: "",
    link: "",
    icon: (
      <span className="icon" style={MENU_ICON_SPAN_STYLE}>
        <GiTicket size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.compliance",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_KYB,
    mainMenuId: M_EBS_PAY_MAIN_MENU_COMPLIANCE,
    link: "/main/compliance/kyb",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdSecurity size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.kyb",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_KYC,
    mainMenuId: M_EBS_PAY_MAIN_MENU_COMPLIANCE,
    link: "/main/compliance/kyc",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdSecurity size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.kyc",
  },
  {
    _isMainMenu: true,
    menuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    mainMenuId: "",
    link: "",
    icon: (
      <span className="icon" style={MENU_ICON_SPAN_STYLE}>
        <RiBankLine size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.payment",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_PAYMENT_INITIAL,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/payment/paymentinitiation",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdPayment size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.payment_single",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CROSS_BORDER_PAYMENT_INITIATE,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/payment/crossborderpaymentinitiation",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdPayment size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.cross_border_payment",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CROSS_BORDER_PAYMENT_APPROVAL_AND_STATUS,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/payment/crossborderpaymentapproveandstatus",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <RiShieldCheckFill size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.cross_border_payment_approve_and_status",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_FILE_UPLOAD,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/maintenance/fileupload",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <FaUpload size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.pif-upload",
  },
  // {
  //   _isMainMenu: false,
  //   menuId: M_EBS_PAY_PAYMENT_STATUS,
  //   mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
  //   link: '/main/payment/paymenthistory',
  //   icon: (
  //     <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
  //       <FaHistory size={MENU_ICON_SIZE} />
  //     </span>
  //   ),
  //   title: 'sidebar.payment_history',
  // },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_PAYMENT_STATUS,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/payment/paymentstatus",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <FaHistory size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.payment_history",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_LIMITAPPROVAL,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/payment/limitapproval",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <FaTachometerAlt size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.limitApproval",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_COMPLIANCE_APPROVAL,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/payment/complianceapproval",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdSecurity size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.complianceApproval",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_APPROVAL,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/payment/payment/approval",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <RiShieldCheckFill size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.paymentApproval",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_DISBAPPROVAL,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/payment/disbursement/approval",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <RiShieldCheckFill size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.disbursementApproval",
  },
  // {
  //   _isMainMenu: false,
  //   menuId: M_EBS_PAY_TRANSACTION_COMPLIANCE,
  //   mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
  //   link: '/main/payment/transaction-compliance',
  //   icon: (
  //     <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
  //       <RiShieldCheckFill size={MENU_ICON_SIZE} />
  //     </span>
  //   ),
  //   title: 'sidebar.transactioncompliance',
  // },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_TRANSACTION_COMPLIANCE,
    mainMenuId: M_EBS_PAY_MAIN_MENU_PAYMENT,
    link: "/main/payment/transaction/compliance",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <RiShieldCheckFill size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.transactioncompliance",
  },
  {
    _isMainMenu: true,
    menuId: M_EBS_PAY_MAIN_MENU_CONFIGURATION,
    mainMenuId: "",
    link: "",
    icon: (
      <span className="icon" style={MENU_ICON_SPAN_STYLE}>
        <MdSettingsApplications size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.configuration",
  },

  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_FI_CONFIGURATION,
    mainMenuId: M_EBS_PAY_MAIN_MENU_CONFIGURATION,
    link: "/main/configuration/fi-configuration",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <RiFileSettingsLine size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.FI_configuration",
  },
  // DEMO Switching
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CONV_CONNECTIVITY_SETUP,
    // mainMenuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
    mainMenuId: M_EBS_PAY_MAIN_MENU_CONFIGURATION,
    link: "/main/converter/in-out-setup",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <BiTransfer size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.converter-in-out-setup",
  },
  // ///////
  {
    _isMainMenu: true,
    menuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
    mainMenuId: "",
    link: "",
    icon: (
      <span className="icon" style={MENU_ICON_SPAN_STYLE}>
        <FaFileImport size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.converter",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CONV_FILEUPLOAD,
    mainMenuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
    link: "/main/converter/file-upload",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <FaUpload size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.converter-file-upload",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CONV_PROCESSING,
    mainMenuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
    link: "/main/converter/dashboard",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <GiProcessor size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.converter-processing",
  },
  // {
  //   _isMainMenu: false,
  //   menuId: M_EBS_PAY_CONV_CONNECTIVITY_SETUP,
  //   mainMenuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
  //   link: '/main/converter/in-out-setup',
  //   icon: (
  //     <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
  //       <BiTransfer size={MENU_ICON_SIZE} />
  //     </span>
  //   ),
  //   title: 'sidebar.converter-in-out-setup',
  // },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CONV_MESSAGE,
    mainMenuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
    link: "/main/converter/delivery-setup",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <TbFileSettings size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.converter-transform-setup",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CONV_ISOCONFIG,
    mainMenuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
    link: "/main/converter/iso-translation-configuration",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <GoGitMerge size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.converter-iso-translation-configuration",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CONV_SENDER_RECEIVER_SETUP,
    mainMenuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
    link: "/main/converter/party-configuration",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <FaUsersCog size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.sender_receiver-configuration",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CONV_ROUTE_CODE_SETUP,
    mainMenuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
    link: "/main/converter/routing-code-configuration",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdAltRoute size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.routing-code-configuration",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_CONV_SERVICE_ROUTING_CONF,
    mainMenuId: M_EBS_PAY_MAIN_MENU_CONVERTER_FORMATTER,
    link: "/main/converter/service-routing-configuration",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdDeviceHub size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.service-routing-configuration",
  },
  {
    _isMainMenu: true,
    menuId: M_EBS_PAY_MAIN_MENU_SECURITY_MANAGEMENT,
    mainMenuId: "",
    link: "",
    icon: (
      <span className="icon" style={MENU_ICON_SPAN_STYLE}>
        <MdSecurity size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.security",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_SECURITY_ROLE,
    mainMenuId: M_EBS_PAY_MAIN_MENU_SECURITY_MANAGEMENT,
    link: "/main/security/role",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <BsPersonLinesFill size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.security_user_role",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_SECURITY_USER,
    mainMenuId: M_EBS_PAY_MAIN_MENU_SECURITY_MANAGEMENT,
    link: "/main/security/usermanagement",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <FaUserAlt size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.security_user_management",
  },
  // REPORTSSS
  {
    _isMainMenu: true,
    menuId: M_EBS_PAY_MAIN_MENU_REPORT,
    mainMenuId: "",
    link: "",
    icon: (
      <span className="icon" style={MENU_ICON_SPAN_STYLE}>
        <FaFileInvoice size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.reports",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_REPORT,
    mainMenuId: M_EBS_PAY_MAIN_MENU_REPORT,
    link: "/main/reports",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <FaFileInvoice size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.reports",
  },
  // RTP PAYMENTS
  {
    _isMainMenu: true,
    menuId: M_EBS_PAY_MAIN_MENU_RTPPAYMENT,
    mainMenuId: "",
    link: "",
    icon: (
      <span className="icon" style={MENU_ICON_SPAN_STYLE}>
        <MdPayment size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.rtp",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_RTP_INITIATION,
    mainMenuId: M_EBS_PAY_MAIN_MENU_RTPPAYMENT,
    link: "/main/rpayments/paymentinitiation",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <MdPayment size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.rtpinitiation",
  },
  {
    _isMainMenu: false,
    menuId: M_EBS_PAY_RTP_STATUS,
    mainMenuId: M_EBS_PAY_MAIN_MENU_RTPPAYMENT,
    link: "/main/rpayments/paymentstatus",
    icon: (
      <span className="icon" style={MENU_SUB_ICON_SPAN_STYLE}>
        <FaHistory size={MENU_ICON_SIZE} />
      </span>
    ),
    title: "sidebar.rtpstatus",
  },
];
