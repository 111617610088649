import { API_METHOD, REQUEST_TYPE } from '../config/APIConfigList'
import { SESSION_STORAGE_ITEM } from '../config/StaticProperties'
export const JSON_HEADER = {
  accept: '*/*',
  'Content-Type': 'application/json',
  // "Access-Control-Allow-Origin": "*",
}

export const MULTIPART_HEADER = {
  // "Content-Type": "application/x-www-form-urlencoded",
}

export const CATCH = {
  success: false,
  msg: 'Timeout Error',
  code: '',
  data: 'Timeout Error',
}

export const RequestHandler = ({ url, method, requestType, body }) => {
  let config = {}
  if (
    requestType === REQUEST_TYPE.NORMAL ||
    requestType === REQUEST_TYPE.FILEDOWNLOAD ||
    requestType=== REQUEST_TYPE.BLOBDOWNLOAD
  ) {
    
    config = {
      method: method,
      headers: {
        ...JSON_HEADER,
        'User-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.USER_TOKEN) ||
          'No Token Available',
        'Access-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.ACCESS_TOKEN) ||
          'No Token Available',
        'Refresh-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.REFRESH_TOKEN) ||
          'No Token Available',
      },
    }
    if (body && body !== '') {
      config = { ...config, body: JSON.stringify(body) }
    }
  } else if (requestType === REQUEST_TYPE.MULTIPART) {
    config = {
      method: method,
      headers: {
        ...MULTIPART_HEADER,
        'User-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.USER_TOKEN) ||
          'No Token Available',
        'Access-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.ACCESS_TOKEN) ||
          'No Token Available',
        'Refresh-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.REFRESH_TOKEN) ||
          'No Token Available',
      },
    }
    if (body && body !== '') {
      config = { ...config, body }
    }
  }
  return fetch(url, config)
    .then((response) => {
      if (requestType === REQUEST_TYPE.FILEDOWNLOAD) {
        return response
      } if (requestType === REQUEST_TYPE.BLOBDOWNLOAD) {
        return response.blob()
      } else {
        return response.json()
      }
    })
    .then(formatResponse)
    .catch((response) => {
      return {
        ...CATCH,
        code: response.status,
      }
    })
}

export const RequestHandlerConverter = ({ url, method, requestType, body }) => {
  let config = {}
  if (requestType === REQUEST_TYPE.NORMAL) {
    config = {
      method: method,
      headers: {
        ...JSON_HEADER,
        'User-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.USER_TOKEN) ||
          'No Token Available',
        'Access-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.ACCESS_TOKEN) ||
          'No Token Available',
        'Refresh-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.REFRESH_TOKEN) ||
          'No Token Available',
      },
    }
    if (body && body !== '') {
      config = { ...config, body: JSON.stringify(body) }
    }
  } else if (requestType === REQUEST_TYPE.MULTIPART) {
    config = {
      method: method,
      headers: {
        ...MULTIPART_HEADER,
        'User-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.USER_TOKEN) ||
          'No Token Available',
        'Access-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.ACCESS_TOKEN) ||
          'No Token Available',
        'Refresh-Token':
          sessionStorage.getItem(SESSION_STORAGE_ITEM.REFRESH_TOKEN) ||
          'No Token Available',
      },
    }
    if (body && body !== '') {
      config = { ...config, body }
    }
  }

  return fetch(url, config)
    .then((response) => {
      return response.json()
    })
    .then(formatResponse)
    .catch((response) => {
      return {
        ...CATCH,
        code: response.status,
      }
    })
}

export const formatResponse = (response) => {
  if (
    response.status !== undefined &&
    response.status !== null &&
    response.status !== 200
  ) {
    return { ...CATCH, code: response.status }
  } else {
    return Promise.resolve(response)
  }
}
