import React, { useState } from "react";
import { Layout, Popover } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
import SearchBox from "../../components/SearchBox";
import UserInfo from "../../components/UserInfo";
import AppNotification from "../../components/AppNotification";
import MailNotification from "../../components/MailNotification";
import Auxiliary from "util/Auxiliary";
import blueone from '../../../src/assets/vendors/img/blueone.png'
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import UserProfile from "../Sidebar/UserProfile";
import {
  COMPANY_IMG_URL,
  IMAGE_NOT_AVAILABLE,
} from "../../routes/main/Profile/ProfileConst";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  CHECK_PAGE_PERMISSION,
  M_EBS_PAY_FI_DETAIL,
} from "../Sidebar/SidebarConst";

const { Header } = Layout;

const Topbar = () => {
  const userInfo = useSelector((reducerDetails) => {
    if (reducerDetails) {
      return reducerDetails?.rolePermission?.userInfo || null;
    }
    return null;
  });
  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [comapnyLogo, setCompanyLogo] = React.useState("");
  const history = useHistory();
  React.useEffect(() => {
    if (userInfo !== undefined && userInfo !== null) {
      setUserInfo();
    } else {
      setCompanyLogo("");
    }
  }, [userInfo]);
  const setUserInfo = async () => {
    let logo = await COMPANY_IMG_URL(userInfo?.user?.partyLogo || null);
    if (logo !== IMAGE_NOT_AVAILABLE) {
      setCompanyLogo(logo);
    } else {
      setCompanyLogo("");
    }
  };

  console.log(`USERDETAILS`, userInfo);
  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}

      {comapnyLogo && comapnyLogo !== "" ? (
        <Link to="/" className="gx-d-block gx-pointer">
          <img
            src={userInfo?.user?.partyName.toLowerCase()==="blueone"?blueone:comapnyLogo}
            style={{
              width: userInfo?.user?.partyName.toLowerCase()==="blueone"?'':30,
              height: 30,
              objectFit: "contain",
              borderRadius: userInfo?.user?.partyName.toLowerCase()==="blueone"?'0%':"100%",
              boxShadow: userInfo?.user?.partyName.toLowerCase()==="blueone"?"none":"0 3px 2px rgb(0 0 0 / 40%)",
              objectFit: "cover",
            }}
            alt={"Company Logo"}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = IMAGE_NOT_AVAILABLE;
            }}
          />
        </Link>
      ) : (
        <></>
      )}

      <div
        style={{
          marginLeft: 20,
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <span style={{ fontSize: 16, fontWeight: 500 }}>
          {userInfo?.user?.partyName.toLowerCase()==="blueone"?"":userInfo?.user?.partyName || ""}
        </span>
        {CHECK_PAGE_PERMISSION(userInfo?.permission, M_EBS_PAY_FI_DETAIL) && (
          <span
            style={{ fontSize: "12px", color: "#4782ea", cursor: "pointer" }}
            onClick={() => history.push("/main/maintenance/fiprofile")}

          >
            View Profile
          </span>
        )}
      </div>
      <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={
              <SearchBox
                styleName="gx-popover-search-bar"
                placeholder="Search in app..."
                onChange={updateSearchChatUser}
                value={searchText}
              />
            }
            trigger="click"
          >
            <span className="gx-pointer gx-d-block">
              <i className="icon icon-search-new" />
            </span>
          </Popover>
        </li>
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-notify">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<AppNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-notification" />
                </span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<MailNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="icon icon-chat-new" />
                  <span className="gx-status gx-status-rtl gx-small gx-orange" />
                </span>
              </Popover>
            </li>
          </Auxiliary>
        )}
        {width >= TAB_SIZE ? (
          <UserProfile />
        ) : (
          <li className="gx-language">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={languageMenu()}
              trigger="click"
            >
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <i className={`flag flag-24 flag-${locale.icon}`} />
                <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                <i className="icon icon-chevron-down gx-pl-2" />
              </span>
            </Popover>
          </li>
        )}

        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-user-nav">
              <UserInfo />
            </li>
          </Auxiliary>
        )}
      </ul>
    </Header>
  );
};

export default Topbar;
